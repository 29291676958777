import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLinkProps } from "../../Utils/Types/NavLink";
import firstLetterUppercase from "../../Utils/firstLetterUppercase";

const NavLink = (props: NavLinkProps) => {
  const location = useLocation();
  let pathname: string = location.pathname;
  if (pathname === "/") pathname = "/home";

  return (
    <Link className={`navLink ${pathname.slice(1) === props.path ? "active" : "passive"}`} to={props.path}>
      {firstLetterUppercase(props.path)}
    </Link>
  );
};

export default NavLink;
