import React from "react";
import "../../Css/Layout/Nav.css";
import Logo from "../../Assets/logo.png";
import NavLink from "./NavLink";

const Nav = () => {
  return (
    <div className="nav">
      <div className="logo">
        <img src={Logo} alt="logo" />
        <div className="text">
          <div className="first">Ariza.</div>
          <div className="second">Client</div>
        </div>
      </div>
      <div className="links">
        <NavLink path="home" />
        <NavLink path="download" />
        <NavLink path="discord" />
      </div>
    </div>
  );
};

export default Nav;
