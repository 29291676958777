import React from "react";
import { FeatureProps } from "../../Utils/Types/Feature";
import "../../Css/Home/Feature.css";

const Feature = (props: FeatureProps) => {
  return (
    <div className="feature">
      <img src={props.icon} alt="icon" className="icon" />
      <div className="title">{props.title}</div>
      <div className="desc">{props.desc}</div>
    </div>
  );
};

export default Feature;
