import React from "react";
import Crown from "../../Assets/crown.png";
import Stars from "../../Assets/stars.png";
import Computer from "../../Assets/computer.png";
import Game from "../../Assets/game.png";
import "../../Css/Home/Home.css";
import Feature from "./Feature";

const Home = () => {
  return (
    <div className="home">
      <img src={Crown} alt="crown" className="crown" />
      <div className="text1">Coming Soon!</div>
      <div className="text2">Elevate your gameplay with Ariza Client</div>
      <div className="text3">An innovative MC:BE Client for all needs</div>
      <div className="features">
        <Feature icon={Stars} title="Play your way, In style" desc="Ariza client offers endless and intuitive mods for all of your needs" />
        <Feature icon={Computer} title="Best In Class User Experience" desc="A stunning, minimalistic, and of course, clean user interface packed with many features" />
        <Feature icon={Game} title="Game without limits" desc="With an In-game FPS booster and limitless customization, We got you covered" />
      </div>
    </div>
  );
};

export default Home;
